<script>
export default {
  data() {
    return {
      languages: ['en', 'pt'],
    }
  },
  methods: {
    changeLanguage(language) {
      this.$i18n.locale = language;
    },
  },
}
</script>

<template>
    <span @click="changeLanguage('pt')" class="cursor-pointer">
      <img src="/img/flags/brazil.svg" alt="Português" class="w-6 h-6" />
    </span>
    <span @click="changeLanguage('en')" class="cursor-pointer">
      <img src="/img/flags/united-states.svg" alt="English" class="w-6 h-6" />
    </span>
</template>

<style scoped lang="scss">

</style>
